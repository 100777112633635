import './App.css';
import Main from "./pages/main";


function App() {
  return (
    <Main>

    </Main>
  );
}

export default App;
