import NavBar from '../components/navbar/navbar';
import Landing from "../components/landing/landing";


function Main() {
    return (
        <div>
            <NavBar/>
            <Landing/>
        </div>
    )
}


export default Main;